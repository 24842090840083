export const intro_page = {
  hero_section: {
    main_title: "Dein personalisierbares\nOnline-Gesetzbuch.",
    beta_label: "BETA",
    slogan: "Jura. Einfach. Digital.",
    use_for_free: "Kostenlos nutzen!",
    preview_element: {
      law_side: {
        title: "Gesetz.",
        description:
          "<strong>Links</strong> findest du die Gesetzesnormen und personalisierst sie:",
        features: {
          marking: "Markieren",
          linking: "Verlinken",
          bookmarking: "Bookmarken",
          more: "u.v.m.",
        },
      },
      knowledge_side: {
        title: "Wissen",
        description:
          "<strong>Rechts</strong> findest du das dazugehörige Wissen in Form von:",
        features: {
          notes: "Notizen",
          own_schemata: "Eigenen Schemata",
          lexmea_schemata: "LexMea-Schemata",
          more: "u.v.m.",
        },
      },
    },
  },
  features_section: {
    title: "Funktionen.",
    description: "Personalisiere dein Gesetzbuch.",
    first_feature: {
      title: "Unterstreichen,<br>Markieren & Verlinken",
      description:
        "Erkenne auf einen Blick,<br>wo etwas Wichtiges in den Normen steckt!",
    },
    second_feature: {
      title: "Notizen und<br>Definitionen anlegen",
      description:
        "Mache deine Notizen direkt am Gesetz:<br>Anmerkungen, Definitionen und alles was du dir schnell merken willst!",
    },
    third_feature: {
      title: "Schemata ansehen<br>oder eigene erstellen",
      description:
        "Sieh dir zu allen wichtigen Normen Schemata von LexMea an oder verfasse deine eigenen!",
    },
  },
  advantages_section: {
    title: "Vorteile.",
    description: "Nutze die Vorteile der Digitalisierung.",
    first_feature: {
      title: "Verknüpfe dein Wissen",
      description: "durch die dauerhafte Speicherung an der passenden Norm.",
    },
    second_feature: {
      title: "Finde alles",
      description:
        "dank digitaler Speicherung und zentraler <wbr/> Suche für Gesetze, Schemata und Notizen",
    },
    third_feature: {
      title: "Studiere nachhaltig",
      description:
        "und verzichte auf ⌀ 35.000 Seiten* für analoge Gesetze und Nachlieferungen",
    },
  },
  testimonials_section: {
    title: "Meinungen.",
    description: "Was andere über LexMea sagen:",
    wischmeyer_testimonial: {
      text: "Zentral für eine gute Klausurlösung<br>ist die Verknüpfung von Norm und Wissen.<br>Dabei hilft LexMea.",
      author: "Prof. Dr. Thomas Wischmeyer",
      picture_attribution: "Foto von Marzena Skubatz",
      position:
        "Professor für Öffentliches Recht und Recht der Digitalisierung",
    },
  },
  data_ownership_section: {
    title: "Datensouveränität.",
    description: "Wir machen kein Geld mit deinen Daten.",
    data_ownership:
      'Deine <a href="/datenschutzhinweis" class="no-underline">Daten</a> verkaufen wir nie! <br> Die bleiben sicher -<br> auf europäischen Servern.',
    free_features:
      'Alle Grundfunktionen von LexMea<br>sind für Dich zu jedem Zeitpunkt<br><strong class="text-3xl font-extrabold md:text-4xl">KOSTENLOS.</strong>',
    financing:
      "LexMea wird sich langfristig<br>über juristische Stellenanzeigen<br>und Repetitorien finanzieren.",
  },
};
