export const team_mode = {
  create_channel: "Channel anlegen",
  share_document: "Dokument teilen",
  subscribe_channel: "Channel abonnieren",

  join_channel_dialog: {
    text: "Einem Broadcast-Channel beitreten, um die geteilten Inhalte direkt an der passenden Norm einzusehen.",
    user_name_help_text: "Gib einen Benutzernamen ein und drücke Enter.",
    user_name_label: "Benutzername*",
    channel_label: "Channel*",
    password_label: "Password*",
    channel_color_help_text: "Icon für Channel-Inhalte auswählen",
    user_not_found: "Benutzer nicht gefunden.",
  },

  share_channel_dialog: {
    share_in_channel: "In Channel teilen",
  },

  create_channel_dialog: {
    channel_name: "Channelname",
    channel_name_help_text:
      "Der Channelname darf max. 20 Zeichen enthalten (z.B. „BGB AT WS24/25“). Der Benutzername (z.B. Prof-Musterfrau) sollte nicht enthalten sein. Dieser wird ohnehin dazu angezeigt.",
    password_protected_help_text: "Passwort erforderlich?",
    password: "Passwort",
    password_repeat: "Passwort (wiederholen)",
    create_channel: "Channel anlegen",
    download_allowed_help_text: "Download erlaubt?",
  },
};
