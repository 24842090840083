<template>
  <div class="flex flex-col gap-3">
    <div class="flex flex-col gap-4">
      <p class="text-center text-sm text-gray-500">
        Hilf uns, LexMea besser an deine Bedürfnisse anzupassen.
      </p>
      <OccupationsSelect
        v-model:university="form.university_id"
        v-model:occupation="form.occupation_id"
        :optional="true"
      />
      <CitiesSearch v-model="form.city_id" :optional="true" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IUser } from "~/models/IUser";

const props = defineProps<{
  modelValue: Partial<IUser>;
  errors: Partial<Record<keyof IUser, string>>;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: Partial<IUser>];
}>();

const form = useVModel(props, "modelValue", emit);
</script>
