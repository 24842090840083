import type de from "./de";
import { bookshelf } from "~/i18n/en/bookshelf";
import { intro_page } from "~/i18n/en/introPage";
import { entries } from "~/i18n/en/entries";
import { team_mode } from "~/i18n/de/teamMode";
import { colors } from "~/i18n/en/colors";

const en: typeof de = {
  library: "Library",
  workspace: "Workspace",
  info_site: "Info Pages",
  misc: "Misc.",
  intro: "Intro",
  vision: "Vision",
  team: "Team",
  faq: "F.A.Q.",
  terms_and_conditions: "Terms and Conditions",
  data_protection: "Data Protection",
  imprint: "Imprint",
  schemata: "Schemas",
  feedback: "Feedback",
  eraser: "Eraser",
  eraser_help_text: "Erase: Click on Personalization.",
  offline: "You are offline",
  updates: "Updates",
  subscribe: "Subscribe",
  cancel: "Cancel",
  apply_changes: "Apply Changes",
  intro_page,
  bookshelf,
  entries,
  team_mode,
  colors,
};

export default en;
