import type { MessageContext } from "vue-i18n";

const wordForEntryType = ({ named, linked }: MessageContext) => {
  const type = named("type");
  return linked(`entries.${type}`);
};

export const entries = {
  schema: "schema | schemas",
  note: "note | notes",
  mark: "mark | marks",
  underline: "underline | underlines",
  link: "link | links",
  link_chain: "link chain | link chains",
  bookmark: "bookmark | bookmarks",
  all: "all",
  deletion: {
    abort: "cancel",
    delete: "delete",
    title: ({ named, linked }: MessageContext) => {
      const type = named("type");
      const word = linked(`entries.${type}`);
      return `delete ${word}`;
    },
    subtitle: "Markierung löschen",
    are_you_sure: (ctx: MessageContext) => {
      const word = wordForEntryType(ctx);
      return `Are you sure you want to delete this ${word}?`;
    },
    restore: (ctx: MessageContext) => {
      const word = wordForEntryType(ctx);
      const lib = ctx.linked("library");
      const chronic = ctx.linked("bookshelf.tabs.chronic");
      const deleted = ctx.linked("bookshelf.chronic.deleted.heading");
      return `You can restore the ${word} at ${lib} > ${chronic} > ${deleted} for up to three months before it is permanently deleted.`;
    },
  },
};
