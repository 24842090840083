export const bookshelf = {
  login: "logge dich ein",
  register: "registriere dich",
  login_or_register: "{login} bzw. {register}",
  tabs: {
    laws: "Gesetze",
    content: "Inhalte",
    chronic: "Chronik",
  },
  chronic: {
    info: {
      logged_in:
        "Im Arbeitsbereich an Normen angelegte Inhalte (Schemata, Markierungen etc.) werden hier nach Bearbeitungsdatum sortiert angezeigt. Gelöschte Inhalte können binnen 30 Tagen vom Papierkorb aus wiederhergestellt werden.",
      no_user:
        "{action}, um hier deine im Arbeitsbereich an Normen angelegten Inhalte (Schemata, Markierungen etc.) nach Bearbeitungsdatum sortiert angezeigt zu bekommen und gelöschte Inhalte binnen 30 Tagen vom Papierkorb aus wiederherstellen zu können.",
    },
    current: {
      heading: "Inhalte nach Erstellungsdatum",
      subtitle:
        "Hier findest du eine chronologisch sortierte Auflistung der von dir erzeugten Inhalte.",
      empty_all: "Du hast noch nichts angelegt.",
      empty_type: "Du hast noch keine {type} angelegt.",
      no_user:
        "{action}, um hier eine Chronik aller von dir angelegten Inhalte einzusehen.",
    },
    deleted: {
      heading: "Papierkorb",
      subtitle:
        "Hier findest du eine chronologisch sortierte Auflistung der von dir gelöschten Inhalte.",
      empty_all: "Du hast noch keine gelöschten Inhalte.",
      empty_type: "Du hast noch keine gelöschten {type}.",
      no_user: "{action}, um hier deine gelöschten Inhalte einzusehen.",
    },
  },
};
