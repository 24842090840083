import type { PageContext } from "vike/types";
import type { ApiClient, LexmeaApiClient } from "~/api/apiClient";
import { buildApiClient } from "../api/buildApiClient";
import { hasInjectionContext, type InjectionKey } from "vue";
import { KyInstance } from "ky";

export const clientKey: InjectionKey<KyInstance> = Symbol("apiClient");

export function useApiClient<T extends LexmeaApiClient>(
  apiClass: new (client: ApiClient) => T,
  pageContext?: PageContext
) {
  const inVue = hasInjectionContext();
  // cache the instance of the api class using vue's provide / inject API
  if (inVue) {
    const injected = injectLocal(apiClass.name, null);
    if (injected) return injected;
  }

  let client: KyInstance | null = null;
  // try to reuse existing instance
  if (inVue) {
    client = injectLocal(clientKey, null);
  }
  // build if it doesn't exist
  if (!client) {
    client = buildApiClient(pageContext);
    if (inVue) {
      provideLocal(clientKey, client);
    }
  }
  const instance = new apiClass(client);
  if (inVue) {
    provideLocal(apiClass.name, instance);
  }
  return instance;
}
