export const bookshelf = {
  login: "log in",
  register: "register",
  login_or_register: "{login} or {register}",
  tabs: {
    laws: "Laws",
    content: "Content",
    chronic: "Chronic",
  },
  chronic: {
    info: {
      logged_in:
        "Content created in the workspace according to standards (schemas, markings, etc.) is displayed here sorted by edit date. Deleted content can be restored from the trash within 30 days.",
      no_user:
        "{action}, to get your content created in the workspace according to standards (schemas, markings, etc.) displayed here sorted by edit date and to be able to restore deleted content from the trash within 30 days.",
    },
    current: {
      heading: "Content by Creation Date",
      subtitle:
        "Here you will find a chronologically sorted list of the content you have created.",
      empty_all: "You haven't created anything yet.",
      empty_type: "You haven't created any {type} yet.",
      no_user:
        "{action}, to view a chronicle of all the content you have created here.",
    },
    deleted: {
      heading: "Trash",
      subtitle:
        "Here you will find a chronologically sorted list of the content you have deleted.",
      empty_all: "You haven't deleted any content yet.",
      empty_type: "You haven't deleted any {type} yet.",
      no_user: "{action}, to view your deleted content here.",
    },
  },
};
