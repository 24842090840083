<template>
  <LexmeaSelect
    :modelValue="parentId"
    :label="'Tätigkeit' + (optional ? ' (optional)' : '')"
    :options="[
      ...occupations.map((t) => ({
        label: t.name,
        value: t.id,
      })),
      { label: '- Keine Angabe -', value: undefined },
    ]"
    @update:modelValue="handleParentUpdate"
  />
  <LexmeaSelect
    v-if="parentOccupation?.children && parentOccupation.children.length > 0"
    :modelValue="childId"
    :label="parentOccupation.name + (optional ? ' (optional)' : '')"
    :options="[
      ...parentOccupation.children.map((t) => ({
        label: t.name,
        value: t.id,
      })),
      { label: '- Keine Angabe -', value: undefined },
    ]"
    @update:modelValue="handleChildUpdate"
  />
  <UniversitiesSearch v-if="showUniversities" v-model="universityModel" />
</template>

<script lang="ts" setup>
interface Props {
  university?: number;
  occupation?: number;
  optional?: boolean;
}

type Emits = {
  "update:university": [data: number];
  "update:occupation": [data: number];
};

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const occupationModel = useVModel(props, "occupation", emit);
const universityModel = useVModel(props, "university", emit);

const { occupations, flattenedOccupations } = useOccupations();

const parentId = ref<number | undefined>();
const childId = ref<number | undefined>();

const selectedOccupation = computed(() =>
  flattenedOccupations.value.find(({ id }) => id === occupationModel.value)
);

const parentOccupation = computed(() =>
  occupations.value.find(({ id }) => id === parentId.value)
);

const showUniversities = computed(
  () => parentOccupation.value?.name === "Studium"
);

const handleParentUpdate = (id?: number) => {
  childId.value = undefined;
  parentId.value = id;
  universityModel.value = undefined;
  const occupation = occupations.value.find((occ) => occ.id === id);

  if (occupation && occupation.children.length > 0) {
    occupationModel.value = undefined;
    return;
  }
  occupationModel.value = id;
};

const handleChildUpdate = (id?: number) => {
  if (id) {
    occupationModel.value = id;
  } else {
    occupationModel.value = parentId.value;
  }
};

watch(selectedOccupation, (occ) => {
  if (!occ) {
    childId.value = undefined;
    return;
  } else if (occ.parent_occupation_id) {
    parentId.value = occ.parent_occupation_id;
    childId.value = occ.id;
  } else {
    parentId.value = occ.id;
    childId.value = undefined;
  }
});
</script>
