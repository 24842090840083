import colors from "~/../colors.json";
import type { EntryColor } from "~/models/IEntryColor";

export function useEntryColors() {
  const allColors = Object.keys(colors["entry"]).map((color) => {
    return {
      name: color as EntryColor,
      darkest: Object.values(colors["entry"][color].darkest).join(""),
      darker: Object.values(colors["entry"][color].darker).join(""),
      default: Object.values(colors["entry"][color].default).join(""),
      lighter: Object.values(colors["entry"][color].lighter).join(""),
      lightest: Object.values(colors["entry"][color].lightest).join(""),
      white: Object.values(colors["entry"][color].white).join(""),
    };
  });

  const noteTextFromColor = (color?: EntryColor) => {
    switch (color) {
      case "green":
        return "Definition";
      case "blue":
        return "Wissen";
      default:
        return "Notiz";
    }
  };

  return { allColors, noteTextFromColor };
}
