<template>
  <div class="flex flex-col gap-4">
    <LexmeaInput
      v-model="form.email"
      name="email"
      :error="errors?.email"
      label="E-Mail Adresse"
      type="email"
    />
    <LexmeaInput
      v-model="form.password"
      name="password"
      :error="errors?.password"
      label="Passwort"
      :type="'password'"
      minlength="9"
    />
    <LexmeaInput
      v-model="form.password_confirmation"
      name="password_confirmation"
      :error="errors?.password_confirmation"
      label="Passwort wiederholen"
      :type="'password'"
      minlength="9"
    />
    <p class="-mt-1 text-xs">
      Verwende 9 oder mehr Zeichen mit einer Mischung aus Buchstaben, Ziffern
      und Symbolen.
    </p>
  </div>

  <div class="flex flex-col gap-2">
    <div class="flex gap-1 gap-x-3 md:gap-x-5">
      <img
        :src="dataProtectionIcon"
        alt="Datenschutzschild"
        class="mt-2 h-[3.625rem] w-12 flex-shrink-0 p-1"
      />
      <div>
        <h3 class="mt-2 text-xs font-bold md:text-xs">
          Extra starker Datenschutz
        </h3>

        <ul class="mt-1 text-[9px] md:text-xs">
          <li>Wir geben deine Daten nie an Werbetreibende weiter.</li>
          <li>Wir nutzen kein Google Analytics, kein Facebook Pixel.</li>
          <li>Wir hosten auf unseren europäischen Servern.</li>
        </ul>
        <p class="mb-2 mt-1 text-[9px] md:text-xs">
          Erfahre mehr dazu in unseren
          <a
            class="text-de font-bold text-lexmea-blue-400 underline"
            href="/datenschutzhinweis"
            target="_blank"
          >
            Datenschutzhinweisen</a
          >.
        </p>
      </div>
    </div>
    <LexmeaCheckbox
      v-model="form.data_privacy_statement_agreed"
      name="data_privacy_statement"
      :error="errors.data_privacy_statement_agreed"
      small
    >
      Ich stimme den
      <a
        class="font-bold text-lexmea-blue-400 underline"
        href="/agb"
        target="_blank"
        >Nutzungsbedingungen</a
      >
      zu.
    </LexmeaCheckbox>
    <LexmeaCheckbox
      v-model="form.newsletter_accepted"
      name="newsletter_accepted"
      :error="errors.newsletter_accepted"
      small
    >
      Ich möchte über Neuigkeiten per E-Mail informiert werden (jederzeit
      widerrufbar, z.B. über Link am Ende jeder Mail).
    </LexmeaCheckbox>
  </div>
</template>

<script setup lang="ts">
import dataProtectionIcon from "~/assets/authentication/dataProtectionShield.svg";
import type { IRegisterForm } from "~/models/IUser";

const props = defineProps<{
  modelValue: Partial<IRegisterForm>;
  errors: Partial<Record<keyof IRegisterForm, string>>;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: Partial<IRegisterForm>];
}>();

const form = useVModel(props, "modelValue", emit);
</script>
