<template>
  <div v-if="label" class="mb-4 flex flex-wrap items-center justify-between">
    <div v-if="!file">
      <label for="file-upload">
        <span
          class="block rounded bg-gray-100 p-2 transition hover:cursor-pointer hover:bg-gray-200"
        >
          {{ label }}
        </span>
      </label>
      <span v-if="typeRestrictions" class="px-2 text-sm">
        Erlaubte Formate: {{ typeRestrictions.join(", ") }}
      </span>
    </div>
    <div v-else>
      <span class="text-sm text-gray-700">Ausgewählte Datei</span>
      <div class="flex items-center justify-between rounded bg-gray-100 p-2">
        <button class="p-1" @click="removeFile">
          <i-heroicons-x-mark-20-solid class="h-5 w-5" />
        </button>
        <p>{{ file?.name }} ({{ fileSize }})</p>
      </div>
    </div>
    <Transition name="scale">
      <span v-if="error" class="px-2 text-sm text-maroon-flush">
        {{ typeof error === "string" ? error : error[0] }}
      </span>
    </Transition>
    <input
      id="file-upload"
      :accept="accept"
      type="file"
      @change="handleFileChange"
    />
  </div>

  <FileUploadIcon
    v-else-if="icon"
    v-tooltip.left="'Profilbild hinzufügen / ändern.'"
    :accept="accept"
    :icon="icon"
    @change="handleFileChange"
  />
</template>

<script lang="ts" setup>
const props = defineProps<{
  icon?: string;
  label?: string;
  file?: File;
  error?: string | string[];
  typeRestrictions?: string[];
  accept?: string;
}>();

const emit = defineEmits<{
  "update:file": [file: File | undefined];
  "update:error": [error: string];
}>();

const fileSize = ref("");

const updateFileSize = (selectedFile: File) => {
  let size = selectedFile.size;
  var fSExt = ["Bytes", "KB", "MB", "GB"],
    i = 0;
  while (size > 900) {
    size /= 1000;
    i++;
  }
  fileSize.value = Math.round(size * 100) / 100 + " " + fSExt[i];
};

const handleFileChange = (e) => {
  const selectedFile: File = e.target.files[0];
  if (props.typeRestrictions) {
    const fileType = selectedFile.type.replace(/(.*)\//g, "");
    if (!props.typeRestrictions.includes(fileType)) {
      emit("update:error", `${fileType} nicht erlaubt`);
      return;
    }
  }
  emit("update:file", selectedFile);
  emit("update:error", "");
  updateFileSize(selectedFile);
  e.target.value = "";
};

const removeFile = () => {
  emit("update:file", undefined);
  fileSize.value = "";
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
</style>
